.speech-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: url(../../assets/6.png);
  .leftSec {
    width: 45%;
    padding: 80px 0 0 80px;

    .speech-title {
      h1 {
        font-size: 44px;
        color: #ffffff;
        font-weight: 700;
        font-family: "Poppins", sans-serif;
        line-height: 60px;
      }
      p {
        font-size: 20px;
        color: #ffffff;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        line-height: 32px;
      }
    }
    .speech-button {
      margin-top: 30px;
      p {
        font-size: 18px;
        color: #ffffff;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        line-height: 28px;
      }
      .button-container {
        img {
          margin-top: 0px;
        }
        .leftImage {
          margin-left: 10px;
        }
      }
    }
  }
  .rightSec {
    width: 55%;
    img {
      margin-bottom: -4px;
      max-width: 491px;
      width: 100%;
    }
  }
}
@media (max-width: 650px) {
  .speech-container {
    display: block;
    padding: 20px 30px;
    .leftSec {
      width: 100%;
      padding: 10px 0 0 10px;
      .speech-title {
        h1 {
          font-size: 24px;
          line-height: 30px;
        }
        p {
          font-size: 13px;
        }
      }
      .button-container {
        .btn-group {
          display: flex;
          justify-content: start;
          margin-bottom: 20px;
        }
      }
    }
    .rightSec {
      width: 100%;
      img {
        max-width: 350px;
        width: 100%;
        margin-bottom: -25px !important;
      }
    }
  }
}
@media (min-width: 651px) and (max-width: 1100px) {
  .speech-container {
    display: block;
    padding: 20px 30px;
    .leftSec {
      width: 100%;
      padding: 10px 0 0 10px;
      .speech-title {
        h1 {
          font-size: 53px;
          line-height: 70px;
        }
        p {
          font-size: 18px;
        }
      }
      .button-container {
        .btn-group {
          display: flex;
          justify-content: start;
          margin-bottom: 20px;
        }
      }
    }
    .rightSec {
      width: 100%;
      text-align: center;
      img {
        width: 100%;
        margin-bottom: -25px !important;
      }
    }
  }
}
