.tab-container {
  width: 100%;
  position: relative;

  .tab-title {
    background: linear-gradient(to bottom,
        rgba(255, 226, 223, 1),
        rgba(218, 243, 251, 1));
    padding: 40px;
    margin-bottom: 20px;
    padding-top: 90px;
    margin-top: -90px;

    p {
      font-size: 18px;
      color: #026080;
      font-weight: 500;
      font-family: "Poppins", sans-serif;
      line-height: 27px;
    }

    h1 {
      font-size: 36px;
      color: #1a1a1a;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      line-height: 54px;
    }
  }
}

.tab-body {
  display: flex;
  justify-content: space-between;

  .leftSec {
    width: 50%;
    padding: 0px 40px;

    .tab {
      .tabs {
        font-family: arial;
        width: 350px;
        text-align: center;
        background: #FF6654;
        display: flex;
        justify-content: center;
        border-radius: 40px
      }




      button {
        margin-right: 15px;
        font-size: 20px;
        background: transparent;
        border: none;
        outline: none;
        padding: 10px 20px;
        cursor: pointer;
        color: rgba(0, 0, 0, .4);
        transition: all ease-in-out .2s;
        border-bottom: 2px solid transparent;
        color: #fff;
        opacity: 62%;

      }

      .active {
        border-bottom: 2px solid #fff;

        color: ffff;
        opacity: 1;
      }



      .tab-para {
        padding: 40px;
      }
    }

    .tab-desc {
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      line-height: 28px;
      letter-spacing: 0.2px;
      margin-top: 20px;
    }

    h5 {
      font-size: 16px;
      color: #292929;
      font-weight: 500;
      font-family: "Poppins", sans-serif;
      line-height: 28px;
      letter-spacing: 0.2px;
      margin-top: 20px;
    }
  }

  .rightSec {
    width: 50%;
    display: flex;
    justify-content: center;

    div {
      position: relative;
    }

    .button-container {
      position: absolute;
      height: 100px;
      bottom: 0px;
      background-image: url(../../assets/heloo.png);
      width: 100%;
      right: 0%;
    }
  }
}

@media (max-width:650px) {
  .tab-body {
    display: block;

    .leftSec {
      width: 100%;
      padding: 20px;

    }

    .rightSec {
      width: 100%;
      display: block !important;

      div {
        position: relative !important;
        width: 100%;
        padding: 10px;

        img {
          width: 100%;

        }

        .button-container {
          width: 100%;
          position: absolute !important;
          display: none;
        }
      }
    }
  }

  .tab-title {
    margin-top: 20px;

    p {
      font-size: 13px !important;

    }

    h1 {
      margin-top: 10px;
      font-size: 18px !important;
      line-height: 30px !important;
    }
  }
}

@media (min-width:651px) and (max-width:1100px) {
  .tab-body {
    display: block;

    .leftSec {
      width: 100%;
      padding: 20px;

    }

    .rightSec {
      width: 100%;
      display: block !important;

      div {
        position: relative !important;
        width: 100%;
        padding: 30px;

        img {
          width: 100%;

        }

        .button-container {
          width: 100%;
          position: absolute !important;
          display: none;
        }
      }
    }
  }

  .tab-title {
    margin-top: 20px;

    p {
      font-size: 18px !important;

    }

    h1 {
      margin-top: 10px;
      font-size: 38px !important;
      line-height: 54px !important;
    }
  }
}


@media (min-width:1100px) and (max-width:1250px) {
  .tab-container {
    .tab-title {
      h1 {
        font-size: 1.5rem;
      }
    }
  }

  .tab-body .rightSec div {
    bottom: 2rem !important;
  }
}

@media (min-width:1251px) and (max-width:1500px) {
  .tab-container {
    .tab-title {
      h1 {
        font-size: 1.5rem;
      }
    }
  }

  .tab-body .rightSec div {
    bottom: 0rem !important;
  }
}