.cmmonCard-container {
  width: 100%;
  padding: 60px 30px;
  display: flex;
  justify-content: space-between;
  background-image: url(../../../assets/6.png);
  // align-items: flex-start; /* Align items at the start of the cross axis */

  @media (max-width: 768px) {
    flex-direction: column; /* Change to column layout on smaller screens */
    align-items: center; /* Center items in the cross axis */
  }

  .leftCommonSec {
    width: 50%;
    display: flex;
    justify-content: start;
    img {
      width: 80%; /* Make the image fill its container */
      display: block; /* Remove any default inline styles */
    }

    @media (max-width: 768px) {
      width: 100%; /* Make left section take full width on smaller screens */
      margin-bottom: 30px; /* Add some spacing between left and right sections */
    }
  }

  .rightCommonSec {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 20px !important;


    h5 {
      font-size: 24px;
      color: #1a1a1a;
      font-weight: 800;
      font-family: "Poppins", sans-serif;
      margin-bottom: 10px; /* Add some space below the heading */
    }

    span {
      font-size: 24px;
      color: #ff6654;
      font-weight: 800;
      font-family: "Poppins", sans-serif;
    }

    p {
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      line-height: 30px;
      white-space: pre-line;
    }
    .textContainer {
      margin-top: 40px;
    }
    .titleContainer {
      width: 100%;
      .common-dot-div {
        display: flex;
        .common-dot {
          margin-top: 10px;
          width: 18px;
          height: 18px;
          background-color: #000;
          border-radius: 18px;
        }
          .common-dot-sub-text{
            .common-dot-text {
              padding-left: 5px;
              padding-right: 5px;
              color: #000;
              font-weight: 500;
              font-size: 16px;
            }
          }
        
      }
    }
    .banner {
      display: flex;
      align-items: center;
      width: 50%;

      p {
        font-size: 16px;
        color: #2d2d2d;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        line-height: 24px;
        margin: 5px;
      }
      hr {
        width: 80px;
        margin-left: 15px;
      }
      .bulletPoint {
        height: 16px;
        width: 16px;
        background: #efa434;
        border-radius: 50%;
      }
    }

    @media (max-width: 768px) {
      width: 100%; /* Make right section take full width on smaller screens */
    }
  }
}
@media (max-width: 650px) {
  .cmmonCard-container {
    width: 100%;
    display: block;
    padding: 20px 30px !important;
    .leftCommonSec {
      width: 100% !important;
      justify-content: center;
    }
    .rightCommonSec {
      width: 100% !important;
      .banner {
        justify-content: center;
        width: 100% !important;
      }
      .titleContainer {
        width: 100%;
        p {
          text-align: justify !important;
        }
      }
    }
  }
}

@media (min-width: 651px) and (max-width: 1000px) {
  .cmmonCard-container {
    width: 100%;
    display: block;
    padding: 20px 30px !important;
    .leftCommonSec {
      width: 100% !important;
      justify-content: center;
    }
    .rightCommonSec {
      width: 100% !important;
      .banner {
        justify-content: center;
        width: 100% !important;
      }
      .titleContainer {
        width: 100%;
        p {
          text-align: justify !important;
        }
      }
    }
  }
}
