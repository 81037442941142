.our-pricing-parent-container {
    background-image: url("../../assets/ourPricingBackgroundImg.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 1000px;
    width: 100%;

    .our-price-text {
        font-size: 32px;
        font-family: "poppins";
        font-weight: 600;
        color: black;
        margin-top: 125px;
        text-align: center;
    }

    .choose-the-perfect-plan-text {
        margin-top: 10px;
        font-size: 18px;
        font-family: "inter";
        font-weight: 400;
        color: black;
        text-align: center;
    }

    .save-plan-text {
        margin-top: 15px;
        font-size: 12px;
        font-family: "poppins";
        font-weight: 500;
        color: black;
        text-align: center;

        .save-text {
            font-weight: 700;
        }
    }

    .card-container-wrapper {
        display: flex;
        gap: 22px;
        justify-content: center;
        margin-top: 70px;
        min-width: 1250px;
        width: 100%;
        .card-containers {
            padding: 0px;

            .price-card {
                height: 610px;
                width: 400px;
                background-image: linear-gradient(to bottom, #026080, #011a22);
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .save-more {
                    margin-top: 30px;
                    font-size: 30px;
                    font-family: "poppins";
                    font-weight: 400;
                    color: white;
                }

                .with-best-pricing {
                    font-size: 30px;
                    font-family: "poppins";
                    font-weight: 700;
                    color: white;
                }

                .choose-plan-text {
                    font-size: 16px;
                    font-family: "poppins";
                    font-weight: 400;
                    color: white;
                }

                .img-and-arrow-icon {
                    margin-top: 66px;
                    display: flex;
                    justify-content: space-between;
                    gap: 30px;

                    .price-card-img {
                        width: 270px;
                        height: 370px;
                    }

                    .arrow-icon {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }

        .premium-card-container {
            height: 610px;
            width: 400px;
            background-color: green;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .price-card {
                height: 610px;
                width: 400px;
                background-color: white;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: start;
                padding-inline: 35px;

                .stater {
                    margin-top: 30px;
                    font-size: 28px;
                    font-family: "poppins";
                    font-weight: 600;
                    color: black;
                }

                .best-for-stuttering {
                    font-size: 14px;
                    font-family: "poppins";
                    font-weight: 400;
                    color: black;
                }

                .doller-price {
                    font-size: 39px;
                    font-family: "poppins";
                    font-weight: 600;
                    color: black;

                    .month-text {
                        font-size: 16px;
                        font-weight: 400;
                    }
                }

                .yearly-text {
                    display: flex;
                    align-items: center;

                    .doller-price {
                        font-size: 39px;
                        font-family: "poppins";
                        font-weight: 600;
                        color: black;

                        .month-text {
                            font-size: 16px;
                            font-weight: 400;
                        }

                        margin-right: 26px;
                    }

                    .save-percentage {
                        font-size: 16px;
                        font-family: "poppins";
                        font-weight: 500;
                        color: white;
                        background-color: #026080;
                        border-radius: 8px;
                        height: 28px;
                        width: 99px;
                        text-align: center;
                    }
                }

                .star-icon {
                    height: 45px;
                    width: 45px;
                    position: absolute;
                    left: -9px;
                }

                .free-text {
                    font-size: 39px;
                    font-family: "poppins";
                    font-weight: 600;
                    color: black;
                    position: absolute;
                    top: 13px;
                    left: 14px;
                }

                .what-will-you-get-text {
                    font-size: 16px;
                    font-family: "poppins";
                    font-weight: 400;
                    color: #7e7e7e;
                    margin-top: 95px;
                }

                .check-icon-and-text-container {
                    margin-top: 22px;
                    display: flex;

                    .check-icon {
                        height: 20px;
                        width: 20px;
                    }

                    .check-list-item {
                        font-size: 14px;
                        font-family: "poppins";
                        font-weight: 400;
                        color: #35353f;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

// / Hide scrollbar for Chrome,
// Safari and Opera 
::-webkit-scrollbar {
    display: none;
}