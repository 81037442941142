.contact-container {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  background-image: url(../../assets/contactUs.png);

  .leftSec {
    width: 50%;
    .contact-title {
      p {
        font-size: 14px;
        color: #212121;
        font-weight: 700;
        font-family: "Poppins", sans-serif;
        line-height: 21px;
      }
      h1 {
        font-size: 38px;
        color: #212121;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        line-height: 60px;
        margin-top: 20px;
      }
      .contact-para {
        font-size: 16px;
        color: #212121;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        line-height: 26px;
        margin: 30px 0px;
      }
    }
  }
  .rightSec {
    width: 50%;
    display: flex;
    justify-content: center;
    .form-group {
      margin-bottom: 20px;
    }
    input {
      padding: 15px;
      width: 371px;
      max-width: 100%;
      border: none;
      &::placeholder {
        font-size: 16px;
        color: #000000;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        line-height: 30px;
      }
    }
    textarea {
      padding: 25px;
      width: 371px;
      max-width: 100%;
      border: none;
      &::placeholder {
        font-size: 12px;
        color: #6eacc1;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        line-height: 30px;
      }
    }
    button {
      width: 100%;
      padding: 12px;
      border: none;
      background: #ff6654;
      color: #ffff;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      line-height: 30px;
      border-radius: 5px;
    }
  }
}

@media (max-width: 768px) {
  .contact-container {
    display: block;
    // padding: 10px 0 0 10px;

    .leftSec {
      width: 100%;
      .contact-title {
        p {
          font-size: 14px;
          color: #212121;
          font-weight: 700;
          font-family: "Poppins", sans-serif;
          line-height: 21px;
        }
        h1 {
          font-size: 16px;
          line-height: 20px;
          margin-top: 10px;
        }
        .contact-para {
          font-size: 14px;
          margin: 30px 0px;
        }
      }
    }
    .rightSec {
      width: 100%;
      justify-content: flex-start;
      padding-bottom: 20px;
      input {
        width: 320px;
      }
      textarea {
        width: 320px;
      }
    }
  }
}

@media (min-width: 651px) and (max-width: 1100px) {
  .contact-container {
    display: block;
    // padding: 10px 0 0 10px;

    .leftSec {
      width: 100%;
      .contact-title {
        p {
          font-size: 18px;
          color: #212121;
          font-weight: 700;
          font-family: "Poppins", sans-serif;
          line-height: 21px;
        }
        h1 {
          font-size: 38px;
          line-height: 40px;
          margin-top: 10px;
        }
        .contact-para {
          font-size: 18px;
          margin: 30px 0px;
        }
      }
    }
    .rightSec {
      width: 100%;
      justify-content: center;
      padding-bottom: 20px;
      input {
        width: 320px;
      }
      textarea {
        width: 320px;
      }
    }
  }
}
