.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}

.logo a {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.logo a span {
  color: #8739fa;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu a {
  display: block;
  padding: 7px 15px;
  color: #212121;
  font-size: 15px;
  font-weight: 500;
  transition: 0.2s all ease-in-out;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.menu a:hover {
  opacity: 1;
  color: #ff6654;
  border-bottom: 5px solid #ff6654;
}

.menu-icon {
  display: none;
}

#menu-toggle {
  display: none;
}

#menu-toggle:checked ~ .menu {
  transform: scale(1, 1);
}

@media only screen and (max-width: 950px) {
  .menu {
    flex-direction: column;
    background-color: #fff;
    align-items: start;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .menu a {
    margin-left: 12px;
  }

  .menu li {
    margin-bottom: 10px;
  }

  .menu-icon {
    display: block;

    font-size: 28px;
    cursor: pointer;
  }
}
ul {
  list-style-type: none;
}

