.comparision-parent {
    background-color: rgba(255, 102, 84, 0.06);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .advantage-text {
        font-family: 'poppins';
        font-weight: 800;
        font-size: 26px;
        color: black;
        padding-top: 60px;
        text-align: center;
    }

    .comperision-row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        padding-top: 52px;
        padding-bottom: 52px;
        position: relative;

        @media (max-width: 650px) {
            flex-direction: column;
            position: unset;
        }

        .vs-text {
            background-color: white;
            height: 100px;
            width: 100px;
            border-radius: 50px;
            font-size: 32px;
            font-weight: 800;
            font-family: 'poppins';
            color: "#494949";
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 45%;
            left: 46.5%;
            @media (max-width: 1500px) {
                left: 45.7%;
            }
            @media (max-width: 1100px) {
                left: 45%;
            }
            @media (max-width: 930px) {
                left: 45%;
                width: 80px;
                height: 80px;
                font-size: 28px;
                border-radius: 40px;
            
            }
            @media (max-width: 720px) {
                left: 44.4%;
            }
            @media (max-width: 650px) {
                position: unset;
            }
        }

        .competitior-container {
            height: 650px;
            width: 35%;
            background-color: #026080;
            border-radius: 20px;
            padding: 34px;
            margin-bottom: 10px;
            
            @media (max-width: 1200px) {
                width: 43%;
            }
            @media (max-width: 970px) {
               height: 720px;
            }
            @media (max-width: 840px) {
                height: 780px;
             }
             @media (max-width: 700px) {
                height: 870px;
             }
            @media (max-width: 650px) {
                width: 85%;
                height: 650px;
            }
            @media (max-width: 500px) {
                width: 95%;
            }
            .our-competitors-text {
                font-family: 'poppins';
                font-weight: 500;
                font-size: 28px;
                color: white;
            }

            .comparision-content-container {
                margin-top: 36px;

                .content-header {
                    font-family: 'poppins';
                    font-weight: 700;
                    font-size: 16px;
                    color: white;
                    display: flex;

                    .white-check-sign {
                        height: 24px;
                        width: 24px;
                        margin-right: 10px;
                    }
                }

                .content-text {
                    margin-top: 14px;
                    font-family: 'poppins';
                    font-weight: 400;
                    font-size: 14px;
                    color: white;
                    opacity: 0.7;
                }
            }
        }
    }
}
