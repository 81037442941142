.assesment-container {
  background-image: url(../../assets/groupcontainer.png);
  height: 500px;
  background-size: contain;
  /* Ensure the background image covers the entire container */
  background-position: center;
  background-repeat: no-repeat;
  /* Center the background image */
}

@media screen and (max-width: 1000px) {
  .assesment-container {
    background-size: contain;
    /* Resize the background image to fit within the container */
    background-repeat: no-repeat;
    /* Position the background image at the top */
  }
}

.group-speech-container-wrapper {
  .dot-and-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .red-dot {
      height: 12px;
      width: 12px;
    }

    .step-process-text {
      font-size: 18px;
      font-family: 'inter';
      font-weight: 500;
      color: black;
    }
  }

  .unlocking-clear-text {
    text-align: center;
    font-size: 32px;
    font-family: 'poppins';
    font-weight: 600;
    color: black;
    margin-top: 8px;
  }

  .group-speech-row-wrapper {
    display: flex;
    justify-content: space-between;
    margin-inline: 90px;
    margin-top: 40px;
    margin-bottom: 40px;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: center;
    }

    .two-div-wrapper {
      display: flex;
      justify-content: space-between;
      width: 45%;

      @media screen and (max-width: 1220px) {
        width: 60%
      }

      @media screen and (max-width: 970px) {
        width: 80%
      }
      @media screen and (max-width: 770px) {
        width: 95%
      }
      @media screen and (max-width: 670px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
      }

      .steps-img-text-wrapper {
        display: flex;
        align-items: center;

        @media screen and (max-width: 1220px) {
          margin-top: 15px;
        }

        .arrow-icon {
          height: 32px;
          width: 32px;
          margin-left: 12%;

          @media screen and (max-width: 1275px) {
            margin-left: 7%;
          }
        }

        .image-and-text-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .step-img{
            height:180px;
            width: 180px;
          }

          .step-no {
            height: 35px;
            width: 35px;
            margin-top: 8px;
          }

          .step-no-text {
            font-size: 18px;
            font-family: 'inter';
            font-weight: 500;
            color: #435460;
            margin-top: 8px;
            text-align: center;
          }
        }

      }
    }

  }


}