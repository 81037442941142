.banner-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  flex-wrap: wrap;
  background-color: white;
  .leftSec {
    width: 55%;

    .title-container {
      p {
        font-size: 12px;
        line-height: 14px;
        color: #026080;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        letter-spacing: 2px;
      }

      h1 {
        font-size: 42px;
        line-height: 50px;
        color: #212121;
        font-weight: 800;
        font-family: "Poppins", sans-serif;
        margin-top: 10px;
      }

      .tittle-text-container {
        font-family: 'poppins';
        font-size: 40px;
        font-weight: 600;
        line-height: 47px;
        text-align: left;
        margin-top: 26px;
      }

      span {
        color: #ff6654;
      }

      .craft-text {
        font-size: 14px;
        line-height: 28px;
        color: #333333;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        margin-top: 60px;
      }
    }

    .arrow-image {
      display: flex;
      justify-content: end;
      margin-top: 20px;
    }

    .button-container {
      img {
        margin-top: 0px;
      }

      .leftImage {
        margin-left: 10px;
      }
    }
  }

  .rightSec {
    width: 40%;

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 650px) {
  .banner-container {
    display: block;

    .leftSec {
      width: 100% !important;

      .title-container {
        width: 100%;

        h1 {
          font-size: 20px !important;
          line-height: 35px !important;
        }

        p {
          font-size: 15px !important;
          line-height: 31px !important;
          text-align: justify !important;
        }
      }

      .button-container {
        .btn-group {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }
      }
    }

    .rightSec {
      width: 100%;
    }
  }

  .arrow-image {
    margin-bottom: 12px !important;
  }

  .btn-group {
    margin-bottom: 12px !important;
  }
}

@media (min-width: 651px) and (max-width: 1100px) {
  .banner-container {
    display: block;

    .leftSec {
      width: 100% !important;

      .title-container {
        width: 100%;

        h1 {
          font-size: 42px !important;
          line-height: 56px !important;
        }

        p {
          font-size: 13px !important;
          line-height: 31px !important;
          text-align: justify !important;
        }
      }

      .button-container {
        .btn-group {
          display: flex;
          margin-bottom: 10px;
        }
      }
    }

    .rightSec {
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
  }

  .arrow-image {
    margin-bottom: 12px !important;
  }

  .btn-group {
    margin-bottom: 12px !important;
  }
}

@media (min-width: 1101px) and (max-width: 1500px) {
  .banner-container {
    padding: 0px 30px !important;
  }
}