.t-c-container{
    .tc-heading-text{
        background-color: rgb(55, 52, 52);
        color: white;
        font-family: 'poppins';
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .welcome-text{
        margin: 20px;
        color: black;
        font-weight: 400;
        font-family: 'poppins';
        font-size: 18px;
    }
    .tc-list{
        margin-inline: 40px;
        .tc-list-text{
            font-size: 16px;
            margin: 20px;
            color: black;
            font-weight: 400;
            font-family: 'poppins'; 
        }
    }
}