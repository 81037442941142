.footer-container {
  //   max-width: 100vw
  width: 100%;
  background-color: #026080;
  display: flex;
  justify-content: space-between;
  padding: 60px 30px;

  @media (max-width: 768px) {
    flex-direction: column; // Switch to column layout on smaller screens

    .left-sec,
    .right-sec {
      padding: 20px;
    }
  }
}

.left-sec {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  img {
    max-width: 200px;
  }

  .footer-text {
    color: #fff;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 1.5;
  }
}

.right-sec {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column; // Wrap links vertically on smaller screens
  }

  .footer-links {
    margin-top: 20px;
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-around;

    @media (max-width: 768px) {
      flex-direction: column; // Stack links vertically on smaller screens
    }

    li {
      display: flex;
      flex-direction: column;
      gap: 10px; // Adjust gap for spacing between heading and links

      .link-title {
        color: #fff;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        text-align: left;
        // Align titles to the left
      }

      .sub-links {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          color: #fff;
          font-size: 14px;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }
}
.copyright-container {
  background: #f9f9f9;
  padding: 20px;
  p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
}

@media (max-width: 650px) {
  .left-sec {
    width: 100%;
  }
}
