@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700&display=swap");

body {
    background: #fafafa;
}

.accordion {
    display: flex;
    flex-direction: column;
    font-family: "Sora", sans-serif;
    max-width: 991px;
    min-width: 320px;
    margin: 50px auto;
    padding: 0 50px;

    .accordion-wrapper {
        display: flex;
        gap: 108px;
        justify-content: center;
        align-items: center;

        .faqs-div {
            width: 20%;
            .faqs-text {
                font-size: 24px;
                font-family: 'poppins';
                font-weight: 500;
                color: #026080;
            }

            .frequently-asked-text {
                font-size: 38px;
                font-family: 'poppins';
                font-weight: 600;
                color: black;
            }

            .questions-text {
                font-size: 38px;
                font-family: 'poppins';
                font-weight: 600;
                color: #FF6654;
            }
        }

        .list-item-div{
            width: 80%;
    
        }
    }

}

.accordion h1 {
    font-size: 32px;
    text-align: center;
}

.accordion-item {
    margin-top: 16px;
    border: 1px solid #fcfcfc;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.accordion-item .accordion-item-title {
    position: relative;
    margin: 0;
    display: flex;
    width: 100%;
    font-size: 15px;
    cursor: pointer;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 14px 20px;
    box-sizing: border-box;
    align-items: center;
}

.accordion-item .accordion-item-desc {
    display: none;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color: #444;
    border-top: 1px dashed #ddd;
    padding: 10px 20px 20px;
    box-sizing: border-box;
}

.accordion-item input[type="checkbox"] {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
}

.accordion-item input[type="checkbox"]:checked~.accordion-item-desc {
    display: block;
}

.accordion-item input[type="checkbox"]:checked~.accordion-item-title .icon:after {
    content: "-";
    font-size: 20px;
}

.accordion-item input[type="checkbox"]~.accordion-item-title .icon:after {
    content: "+";
    font-size: 20px;
}

.accordion-item:first-child {
    margin-top: 0;
}

.accordion-item .icon {
    margin-left: 14px;
}

@media screen and (max-width: 767px) {
    .accordion {
        padding: 0 16px;
    }

    .accordion h1 {
        font-size: 22px;
    }
}

@media screen and (max-width: 630px) {
    .accordion{
        .accordion-wrapper{
            flex-direction: column;
            .faqs-div{
                width: 100%;
                .faqs-text{
                    text-align: center;
                }
                .frequently-asked-text{
                    text-align: center;
                }
                .questions-text{
                    text-align: center;
                }
            
            }
        }
    }
    
}